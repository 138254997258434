import React from 'react'

const BigButton = ({number,modify,color,topText,bottomText}) => {

  const displayColor = (color === 'blue') ?
    'blue'
    : (color === 'red') ?
      '#c80000'
      : 'orange'

  const displayNumber = (color === 'blue') ?
    number
    : (color === 'red') ?
      number.toFixed(8)
      : 21

  const getClassName = () => {
    let className = 'bigButton '
    className += `${color}Button`
    return className
  }

  return (
    <>
        <h2 style={{color:displayColor}}>Welcome! Let's see how high we can get.</h2>

        <div className='countDisplay'>
        <p>{topText}</p>
        <div className='numDisplay'>
            <h1 style={{color:displayColor}}>
            {displayNumber}
            </h1>
        </div>

        <p>{bottomText}</p>
        </div>
        <button className={getClassName()} onClick={modify}
        >Click me!</button>
    </>
  )
}

export default BigButton