
import './App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios'
import BigButton from './components/BigButton';

function App() {

  const url = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  ? 'http://localhost:3500'
  : 'https://worldwide-button-f1d02584c024.herokuapp.com'

  useEffect(() => {
    document.title = 'Worldwide Button'
  }, [])




  const [currentButton, setCurrentButton] = useState('classic')



  // CLASSIC

  const [counter, setCounter] = useState()

  useEffect(() => {
    Axios.get(`${url}/getCounter`).then((response) => {
      setCounter(response.data[0].count)
    })
  }, [counter])

  const addToCount = () => {
    Axios.patch(`${url}/updateCounter`)
    .then((response) => {
      setCounter(counter+1)
    })
  }


  // EXPONENTIAL

  const [exponential, setExponential] = useState()

  useEffect(() => {
    Axios.get(`${url}/getExponential`).then((response) => {
      setExponential(response.data[0].exponential)
    })
  }, [exponential])

  const multiplyExponentialCount = () => {
    Axios.patch(`${url}/updateExponential`)
    .then((response) => {
      setExponential(exponential * 1.001)
    })
  }


  return (
    <div className="App">
      <div className="buttonContainer">
        <button 
          className="toggleButton blueButton"
          onClick={() => setCurrentButton('classic')}
        >Classic</button>

        <button 
          className="toggleButton redButton"
          onClick={() => setCurrentButton('exponential')}
        >Exponential</button>
      </div>

      {currentButton === 'classic' ? 
        <BigButton 
          number={counter} 
          modify={addToCount} 
          color='blue'
          topText='The Worldwide Button has been clicked'
          bottomText='times.'
        />
        : <BigButton 
          number={exponential} 
          modify={multiplyExponentialCount} 
          color='red'
          topText='This is the Exponential Number:'
          bottomText='Click the Exponential Button to multiply it by 1.001.'
        />
      }


    </div>
  );
}

export default App;
